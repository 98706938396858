import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColors } from "./index";


const LineWidget = ({ data, heading,size }) => {
    const [formatData, setFormatData] = useState([]);
    const [branchData,setBranchData] = useState([]);
    
      useEffect(() => {
        setBranchData([]);
        setFormatData(data);
         let finalData = data?.values && Object.keys(data.values)?.map((data1,index)=>{
             setBranchData((curr)=>{
               const newData = [
                ...curr,
                {
                name:data1,
                data: Object.values(data.values)[index],
                },
               ]
               return newData
             })
         })
      }, [data]);
      
  return (
    <div className={`col row mx-0 mt-3 ${size} px-2`}>
    <div className="row px-0 mx-0 justify-content-between col col-12 ">
    <div className="dashboard__card col col-12 w-100 flex-basis-100">
     {heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}
    <div className="d-flex flex-col mx-3 my-2" style={{ gap: "1rem" }}>
    </div>
        <Chart
          width="100%"
          height="350px"
          type="line"
          series={branchData}
          options={{
            chart: {
              type: "line",
              toolbar: {
                show: false
              }
            },
            markers: {
                size: [5, 5]
            },
            stroke: {
                curve: 'smooth',
              },
            plotOptions: {
              bar: {
                distributed: true,
                borderRadius: 10,
              },
            },
            colors: chartThemeColors,
            dataLabels: {
              enabled: false,
            },
            tooltip: {/*
              enabled: true,
              custom: ({ series, seriesIndex, dataPointIndex }) =>
               console.log("abcd44",seriesIndex,series,dataPointIndex)
                `<div class='bar-widget__tooltip'>
            <p class='bar-widget__tooltip-primary'>${
                formatData.bar[dataPointIndex].value_formatted
                ? parseFloat(formatData.bar[dataPointIndex].value_formatted)?.toFixed(2)
                : parseFloat(formatData.bar[dataPointIndex].value)?.toFixed(2)
            }</p>
             
            </div>`,
          */},
            xaxis: {
              categories: formatData.date?.map(date => date),
              labels: { 
                show: true,
                style:{
                  fontSize:"10.5px"
                }
               },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return value ? value?.toFixed(0) : value;
                },
                style:{
                  fontSize:"10.5px"
                }
              },
              tickAmount: 4,
            },
            legend:{
              position:"top",
              fontSize:"10.5px",
              offsetY:0,
            }
          }}
        />
      </div>
    </div>
  </div>
  )
}

export default LineWidget
