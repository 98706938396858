import React, { useEffect, useState, useRef } from "react";
import "./pos.css";
import { useParams, useHistory } from "react-router-dom";

//functions
import {
  _t,
  currencySymbolLeft,
  currencySymbolRight,
  // restaurantMenuLink,
} from "../../../../../functions/Functions";
import { createHash } from "./helpers";

const CartItem = ({
  itemId,
  item,
  deleteItem,
  editItem,
  getPrice,
  addToCart,
  cartItems,
  deleteOneCartItem,
  deletedItemsOnEdit,
  setAddReasonModalShowing,
  orderScheme,
  itemAdd,
  setItemAdd,
  editAdd,
  isLocked,
  authenticateStaffUser,
  // addComments,
  // removeComments,
  updateComments,
  specialInstruction,
  setSpecialInstruction,
  commentId,
  setCommentId,
  animation,
  animate,
  itemAnimate,
}) => {
  //edit order
  const { order_id } = useParams();
  const focusPopUpInputField = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 200);
    }
  };
 
  const itemProperty = [];
  if (parseInt(item.item.has_property) === 1 && Object.keys(item.properties)?.length > 0) {
    Object.values(item.properties).forEach((item) => {
      const properties = Object.values(item);
      properties.forEach((property) => {
        const propertyName = property.item.name;
        itemProperty.push(
          <span className="item_property" key={propertyName}>
           {property.quantity} - {propertyName}
          </span>
        );
      });
      // for (const key in item) {
      //   itemProperty.push(
      //     <span className="item_property" key={key}>
      //       {item[key].item.name}
      //     </span>
      //   );
      // }
    });
  }

  useEffect(()=>{
    animation()
  },[item])


  // check if add in previous items on edit
  useEffect(() => {
    let addItem = {};
    if (editAdd && order_id) {
      for (const key in editAdd) {
        const item = editAdd[key];
        const pickedPropertyAdd = [];
        if (item.hasOwnProperty("properties") && item.properties) {
          const items1 =
            item.properties[
              Object.values(item.properties).forEach((itm) => {
                for (const key in itm) {
                  pickedPropertyAdd.push(itm[key]);
                }
              })
            ];
        }
      
        const hashId = createHash(item);
        addItem = {
          ...addItem,
          [hashId]: {
            ...editAdd[key],
            print_status: "added",
            properties:pickedPropertyAdd ? pickedPropertyAdd : null,
          },
        };
      }
    }
    setItemAdd(addItem);
  }, [editAdd]);
 
  return (
    <div key={itemId} id={itemId ? "cart_Item" : ""} className="pt-2 pb-1 d-flex flex-row justify-content-between border-bottom position-relative pos--cart-item">
      <span className="text-capitalize total-bill d-inline-block col-5 pr-1">
        {item.item.name}
        {parseInt(item.item.has_variation) === 1 ? (
          <span className="item_variation">
            ({item?.variation?.variation_name}){" "}
          </span>
        ) : (
          <></>
        )}
        <br />
        {parseInt(item.item.has_property) === 1 && [itemProperty]}
      </span>
      <span className="text-capitalize total-bill d-inline-block col-3 text-center ">
        
        <button
          disabled = {(order_id && !item.new && isLocked("item_remove_qty_cp")) || item.quantity <= 1}
          className="mx-sm-0 mx-md-1 itemQtyBtn"
          onClick={() => {
            itemAnimate.current = itemId;
            animate.current = "del";
            if(item.offer_type === "bogo" && item.item_discount > 0){
                
            }else{
              if (order_id) {
                  item.new === true ||
                  (deletedItemsOnEdit && deletedItemsOnEdit[itemId]?.status) ||
                  (itemAdd && itemAdd[itemId] && itemAdd[itemId] !== undefined)
                    ? deleteOneCartItem(itemId)
                    : setAddReasonModalShowing({
                        action: "decrease",
                        params: [itemId],
                      });
              } else {
                deleteOneCartItem(itemId);
              }
            }
          }}
        >
          <i class="fa fa-minus"></i>
        </button>
        
        {item.quantity}
        <button
        disabled = {order_id && !item.new ? isLocked("item_remove_qty_cp") : ""}
          className="mx-sm-0 mx-md-1 itemQtyBtn"
          onClick={() =>{
            itemAnimate.current = itemId;
            animate.current = "add";
            if(item.offer_type === "bogo" && item.item_discount > 0){
            }else{
              addToCart(itemId, item)
            }
          }}
        >
          <i class="fa fa-plus"></i>
        </button>
      </span>

      <span
        className="text-capitalize text-break total-bill d-inline-block font-weight-bold col-4 pl-1 text-right"
        style={{ position: "relative" }}
      >
        { 
          item.item_discount > 0 && orderScheme?.cap_amount <= 0 ? (
           <span> 
            <span style={{color:"#e43b53"}}>
           {currencySymbolLeft()}
           {(getPrice(item) - item.item_discount.toFixed(2)).toFixed(2)}
           {currencySymbolRight()}
           </span>
           <del className="ml-1">
           {currencySymbolLeft()}
           {getPrice(item)}
           {currencySymbolRight()}
           </del> 
           </span>      
           )
           :
           <span>
           {currencySymbolLeft()}
           {getPrice(item)}
           {currencySymbolRight()}
           </span>
        }

        <div
          className="position-absolute left-0 bottom-0 right-0 justify-content-end align-items-center pos--cart-actions-menu"
          style={{ background: "white" }}
        >
        {
         /*
          order_id ?
         item.new && (
         <button onClick={(e)=>{
           setSpecialInstruction(true);
           if(item && item !== undefined){
             setCommentId(itemId);
           } 
          
         }
         }
         className="badge badge-secondary btn-info item_comment_icon"
         >
          <i className="fa fa-info" aria-hidden="true"></i>
         </button>
         )
         :
         <button onClick={(e)=>{
            setSpecialInstruction(true);
            if(item && item !== undefined){
              setCommentId(itemId);
            } 
            
          }
          }
          className="badge badge-secondary btn-info item_comment_icon" 
          
          >
           <i className="fa fa-info" aria-hidden="true"></i>
          </button>
         */
        }
        {
         ((order_id && item.new) || !order_id) && (
          <button onClick={(e)=>{
            setSpecialInstruction(true);
            if(item && item !== undefined){
              setCommentId(itemId);
            } 
            
          }
          }
          className="badge badge-secondary btn-info item_comment_icon" 
          
          >
           <i className="fa fa-info" aria-hidden="true"></i>
          </button>
         )
        }
          {/*
            (parseInt(item.item.has_property) === 1 ||
            parseInt(item.item.has_variation) === 1)
            ?
            order_id ?
            (parseInt(item.item.has_property) === 1 ||
             parseInt(item.item.has_variation) === 1) &&
             item.new === true && (
            <button
              onClick={() => editItem(itemId)}
              className="item_edit_icon"
            >
              <i className="fa fa-pencil"></i>
            </button>
          ) : (
            <button
              onClick={() => editItem(itemId)}
              className="item_edit_icon"
            >
              <i className="fa fa-pencil"></i>
            </button>
            ):
            <span></span>
          */}
         {
          (parseInt(item.item.has_property) === 1 || parseInt(item.item.has_variation) === 1) &&
          ((order_id && item.new) || !order_id) && (
            <button onClick={() => editItem(itemId)} className="item_edit_icon">
              <i className="fa fa-pencil"></i>
            </button>
          )
         }
          {order_id && (item.new !== true) &&
            isLocked("item_remove_qty_cp") ? (
            <span
              className="pointer-cursor mx-1 d-flex align-items-center"
              onClick={(e) => authenticateStaffUser("item_remove_qty_cp")}
            >
              <i className="fa fa-lock lock_icon"></i>
            </span>
          ) : (
            <button
              className="item_delete_icon"
              onClick={() => {
                animate.current = false;
                if (order_id) {
                  (item.new === true || (deletedItemsOnEdit && deletedItemsOnEdit[itemId]?.status))
                    ? deleteItem(itemId)
                    : setAddReasonModalShowing({
                        action: "remove",
                        params: [itemId],
                      });
                } else {
                  deleteItem(itemId);
                }
              }}
            >
              <i className="fa fa-trash"></i>
            </button>
          )}
        </div>
      </span>
      <div
      className={`modal fade ${specialInstruction !== false ? "showing" : ""}`}
      id="specialInstruction"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header align-items-center">
              <div className="fk-sm-card__content"></div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => setSpecialInstruction(false)}
              ></button>
           </div>
          <div className="modal-body">
            {/* show form or show saving loading */}
            <form onSubmit={(e) => {
              e.preventDefault();
              animate.current = false;
              setSpecialInstruction(false)
            }}>
              <div>
                <div>
                  <label htmlFor="name" className="form-label">
                    Special Instruction <span style={{color:"red"}}>*</span>
                  </label>
                  <input
                    ref={focusPopUpInputField}
                    type="text"
                    className="form-control"
                    placeholder="comments"
                    name={commentId} 
                    required
                    value={cartItems && cartItems[commentId]?.comments}
                    onChange={(e)=> {
                      updateComments(commentId,e.target.value)
                    }}
                  />
                </div>
              </div>
                      
              <div className="mt-3 mb-1">
                <div className="row d-flex justify-content-center w-100 m-0">
                  <div className="col-3 mr-1">
                    <button
                      type="submit"
                      className="btn btn-success w-100 xsm-text text-uppercase t-width-max rounded-25"
                      data-dismiss="modal"
                      
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-3 ">
                    <button
                      type="button"
                      className="btn btn-primary w-100 xsm-text text-uppercase t-width-max rounded-25"
                      data-dismiss="modal"
                      onClick={(e) => {
                        updateComments(commentId,"",true)
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  );
};

export default CartItem;
