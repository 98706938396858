import CardsWidget from './CardsWidget'
import ColumnsWidget from './ColumnsWidget'
import DonutWidget from './DonutWidget'

/*
  web_type: cards_widget
  details: [
    {
      name: string
      value: number
      formatted_value: string
      icon: string
    },
    ...
  ]
  web_type: bars_widget
  details: {
    bar: [
      {
        name: string,
        value: number,          (eg total_amount)
        value_formatted: string,
        value2: number,         (eg total_orders)
        value2_formatted: string,
      }
      ... 11 others
    ],
    total: [
      {
        name: string,
        value: number,
        value_formatted: string,
      }
    ]
  }
  web_type: donut_widget
  details: {
    chart: [
      {
        name: string,
        value: number,          (eg total_amount)
        value_formatted: string,
        value2: number,         (eg total_orders)
        value2_formatted: string,
      }
      ... 11 others
    ],
    total: [
      {
        name: string,
        value: number,
        value_formatted: string,
      }
    ]
  }

  web_type: donut_widget
  details: {
    donut: {
      {
        name: string,
        value: number,            (eg total_amount)
        value_formatted: string,
        value2: number,           (eg total_orders)
        value2_formatted: string,
      }
      ...
    },
    total: [
      {
        name: string,             (eg Total Orders)
        value: number,
        value_formatted: string,
      }
    ]
  }
*/

export const chartThemeColors = [
  '#0A8D6E',
  '#27CCAA',
  '#80B97D',
  '#72A247',
  '#0d98ba',
  '#24b848',
  '#1d4e54',
  '#E81A43',
  '#50808E',
  '#6fb7bd',
  '#676767',
  '#F08293',
  '#40e0d0',
  '#FFFF00',
  '#9F2B68',
  '#800020',
  '#5D3FD3',
  '#F4B400',
  '#CD7F32',
  '#7B3F00'
]

export const chartThemeColorsBar = [
  '#50808E',
  '#F08293',
  '#676767',
  '#9F2B68',
  '#6fb7bd',
  '#24b848',
  '#40e0d0',
  '#0A8D6E',
  "#CD7F32", 
  '#5D3FD3',
  '#1d4e54',
]

export const chartThemeVerticalBar = [
  '#0A8D6E',

]

export const chartThemeColorsDonut = [
  '#50808E',
  '#0d98ba',
  '#40e0d0',
  "#CD7F32", 
  '#676767',
  '#5D3FD3',
  '#F08293',
  '#800020',
  '#F4B400',
  '#72A247',
  '#F4B400',
  '#9F2B68',
]

export const chartThemeColorsRadial = [
  '#27CCAA',
  '#80B97D',
  '#0d98ba',
  '#676767',
]

export const chartThemeColorsWeek = [
  '#80B97D', 
  '#0d98ba',  
  '#9F2B68',
  "#CD7F32", 
  '#40e0d0', 
  '#50808E', 
  '#0A8D6E',
]

export {
  CardsWidget,
  ColumnsWidget,
  DonutWidget,
}