import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import "./charts/dashboard.css";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  tableLoading,
  checkPermission,
  customStyle,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import moment from "moment";

//pages & includes
import ReportSidebar from "./ReportSidebar";
//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

// graphs
import { CardsWidget, ColumnsWidget, DonutWidget } from "./charts";
import ListWidget from "./charts/ListWidget";
import VerticalBarWidget from "./charts/VerticalBarWidget";
import BarWidget from "./charts/BarWidget";
import LineWidget from "./charts/LineWidget";
import RadialWidget from "./charts/RadialWidget";
import HourlyBar from "./charts/HourlyBar";

const DeicdeVisual = ({ type, data, heading, previous = null }) => {
  switch (type) {
    case "cards_widget":
      return <CardsWidget data={data} heading={heading} previous={previous}/>;
    case "bars_widget":
      return <ColumnsWidget data={data} heading={heading} />;
    case "column_widget":
        return <VerticalBarWidget data={data} heading={heading} size="col-3"/>;
    case "hbars_widget" : 
      return <BarWidget data={data} heading={heading} size="col-3"/>;
    case "pie_widget" : 
     return  <RadialWidget  data={data} heading={heading}/>;
    case "hours_bar" : 
     return  <HourlyBar  data={data} heading={heading}/>;
    case "line_widget" : 
      return  <LineWidget data={data} heading={heading} size="col-8"/>;
      case "donut_widget":
      return <DonutWidget data={data} heading={heading} size="col-3"/>;
      case "list_view" :
      return(
          heading?.toLowerCase() === "branch wise sales" ?
          <div>
          <ListWidget data={data} heading={heading}/>
          </div>
          :
          <div className="d-flex" style={{width:"50%"}}>
      
          <div style={{width:"100%"}}>
            <ListWidget data={data} heading={heading}/>
          </div>
      
        </div>
      );
      default:
      return null;
  }
};

var authUser = "";
var authBranch = "";

const getAxiosReq = (details, params) => {
  const url = `${BASE_URL}/${details.prefix}/${details.endpoint}`;
  if (details.method === "POST") {
    const formData = {};
    if (details.params.includes("range")) {
      formData.range = 12;
    }
    for (const key in params) {
      if (details.params.includes(key)) {
        formData[key] = params[key];
      }
    }
    if(authUser?.details?.user_type === "staff" && !formData.branch_id){
      formData.branch_id = authBranch?.id
      console.log("staff condition running");
    }
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => res.data)
      .catch((err) => err);
  } else if (details.method === "GET")
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => res.data)
      .catch((err) => err);
};

const Reports = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    loading,
    setLoading,
    dataPaginating,
    setDataPaginating,
  } = useContext(SettingsContext);

  let { branchForSearch } = useContext(RestaurantContext);
  let { authUserInfo } = useContext(UserContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [branch, setBranch] = useState(null);

  const [cardData, setCardData] = useState(null);
  const [allApis, setAllApis] = useState(null);

  // auth
  useEffect(() => {
    // TODO: remove this
    // return;
    if (
      authUserInfo?.details?.user_type === "staff" ||
      authUserInfo?.details?.user_type === "call_center" || authUserInfo.details.user_type === "manager"
      || authUserInfo.details.user_type === "multi_manager"
      // !checkPermission(authUserInfo.permissions, "Report Dashboard")
    ) {
     if(checkPermission(authUserInfo.permissions, "Report"))
       history.push("/dashboard/reports");
     /*else if (checkPermission(authUserInfo.permissions, "Daily Report"))
        history.push("/dashboard/daily-reports");
      else if (checkPermission(authUserInfo.permissions, "Monthly Report"))
        history.push("/dashboard/monthtly-reports");*/
      else if (checkPermission(authUserInfo.permissions, "Work Period Report"))
        history.push("/dashboard/work-period-reports");
      else if (checkPermission(authUserInfo.permissions, "Sales Report"))
        history.push("/dashboard/sales-reports");
      else if (checkPermission(authUserInfo.permissions, "Sales Summary Report"))
        history.push("/dashboard/sales-summary-reports");
      else if (checkPermission(authUserInfo.permissions, "Source Wise Report"))
        history.push("/dashboard/source-wise-reports");
      else if (
        checkPermission(authUserInfo.permissions, "Customer Order History")
      )
        history.push("/dashboard/customer-order-history-reports");
      else if (checkPermission(authUserInfo.permissions, "Food Panda Report"))
        history.push("/dashboard/food-panda-reports");
      else if (checkPermission(authUserInfo.permissions, "Meezan Report"))
        history.push("/dashboard/meezan-reports");
      else if (checkPermission(authUserInfo.permissions, "Cancel Order Report"))
        history.push("/dashboard/cancel-order-reports");
      else if (checkPermission(authUserInfo.permissions, "Cancel Item Report"))
        history.push("/dashboard/cancel-made-unmade-reports");
      else if (checkPermission(authUserInfo.permissions, "Hourly Sales Report"))
        history.push("/dashboard/hourly-sales-reports");
      else if (checkPermission(authUserInfo.permissions, "Daily Cash Sales Report"))
        history.push("/dashboard/daily-cash-sales-reports");
      else if (checkPermission(authUserInfo.permissions, "Stock Consumption Report"))
        history.push("/dashboard/stock-consumption-reports");
      else if(checkPermission(authUserInfo.permissions, "Expense Report"))
      history.push("/dashboard/expense-reports");
      else if(checkPermission(authUserInfo.permissions, "Rider Report"))
      history.push("/dashboard/rider-reports");
      else if(checkPermission(authUserInfo.permissions, "Income Statement Report"))
      history.push("/dashboard/income-statement-reports");
      else if(checkPermission(authUserInfo.permissions, "Item Food Cost Report"))
      history.push("/dashboard/food-items-reports");
      else if(checkPermission(authUserInfo.permissions, "Stock Ledger Report"))
      history.push("/dashboard/stock-ledger-reports");
      else if(checkPermission(authUserInfo.permissions, "Finished Goods Report"))
      history.push("/dashboard/finished-goods-ledger-reports");
      else if(checkPermission(authUserInfo.permissions, "Inventory Report"))
      history.push("/dashboard/inventory-reports");
      else if(checkPermission(authUserInfo.permissions, "Discount Wise Report"))
      history.push("/dashboard/discount-reports");
      else if(checkPermission(authUserInfo.permissions, "SRB Report"))
      history.push("/dashboard/srb-report");
      else if(checkPermission(authUserInfo.permissions, "Production Report"))
      history.push("/dashboard/production-reports");
      else if(checkPermission(authUserInfo.permissions, "Stock Variance Report"))
      history.push("/dashboard/stock-variance-reports");
      else if(checkPermission(authUserInfo.permissions, "Stock Transfer Report"))
      history.push("/dashboard/stock-transfer-reports");
      else if(checkPermission(authUserInfo.permissions, "Ingredient Item Report"))
      history.push("/dashboard/ingredient-item-reports");
      else if(checkPermission(authUserInfo.permissions, "Sales Tender Report"))
      history.push("/dashboard/sales-tender-reports");
      else if(checkPermission(authUserInfo.permissions, "Callcenter Report"))
      history.push("/dashboard/call-center-reports");
      else if(checkPermission(authUserInfo.permissions, "Deal's Report"))
      history.push("/dashboard/deal-reports");
      else {
        history.push("/dashboard");
        toast.error("You cannot access any reports.", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      }
    }

    if (authUserInfo.details.user_type === "staff") {
      let branchFilter = branchForSearch.find(
        (branch) => branch.id === authUserInfo.details.branch_id
      )
      setBranch(branchFilter);
      authBranch = branchFilter &&  branchFilter
      authUser = authUserInfo;
    }
  }, [authUserInfo]);

  // data fetching
  useEffect(() => {
    //setLoading(true);
    getReport(() => {
      setLoading(false);
    });
  }, []);

  //get reports
  const getReport = (cb) => {
    const url = BASE_URL + "/widgets/get-api-details";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data.status == 200) {
          const allApisTemp = res.data.details;
          setAllApis(allApisTemp);
          getAllApis(allApisTemp);
        }
      })
      .catch((error) => {})
      .finally(cb);
  };
  const getAllApis = async (allApisTemp, formData = {}) => {
    setDataPaginating(true);
    const allRes = await Promise.all(
      allApisTemp.map((api) => getAxiosReq(api, formData))
    );
    setDataPaginating(false);
    setCardData(
      allRes.map((res, index) => {
        return {
          type: allApisTemp[index].web_type,
          heading: allApisTemp[index].heading,
          data: res.status == 200 ? res.details : { error: res.message },
          ...(allApisTemp[index].web_type.match(/cards(.*)widget/i) && {previous:res.status == 200 ? res.previous : { error: res.message }})
        };
      })
    );
  };

  const handleBranch = (branch) => {
    setBranch(branch);
  };

  const handleApplyFilter = () => {
    const formData = {};
    if (branch && branch.id) formData.branch_id = branch.id;
    if (startDate) formData.from = moment(startDate).format("YYYY-MM-DD");
    if (endDate) formData.to = moment(endDate).format("YYYY-MM-DD");
    getAllApis(allApis, formData);
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Dashboard"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form reports-dashboard">
                        <div className="row gx-2 mx-1 align-items-center t-pt-15 pb-2">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Dashboard"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {/* next page data spin loading  <div className={`${dataPaginating && "loading"}`}></div> */}
                       
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        
                        {/* spin loading ends */}
                        <div className="row mx-1 gx-2 mt-2">
                          <div className="col-3 col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-3 col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <Select
                            options={branchForSearch && branchForSearch}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            value={branch}
                            classNamePrefix="select"
                            className="xsm-text col-3"
                            onChange={handleBranch}
                            styles={customStyle}
                            maxMenuHeight="180px"                            
                            placeholder={_t(t("Select a branch")) + ".."}
                            isClearable
                            isDisabled={authUserInfo ? (authUserInfo?.details?.user_type === "staff") : false}
                          />
                          <div className="col-1 col-md-3"> </div>
                          <div className="col-2 col-md-2 t-mb-15 mb-md-0 d-none d-md-block text-right">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text py-2 rounded "
                              onClick={handleApplyFilter}
                            >
                              {_t(t("View Dashboard"))}
                            </button>
                          </div>

                          <div className="col-2 col-md-3 t-mb-15 mb-md-0 d-block d-md-none">
                            <button
                              className="btn btn-block btn-primary text-uppercase sm-text rounded"
                              onClick={handleApplyFilter}
                            >
                              {_t(t("View Dashboard"))}
                            </button>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2 mb-3">
                          {cardData
                            ? cardData.map((card) => (
                                <DeicdeVisual
                                  type={card.type}
                                  data={card.data}
                                  heading={card.heading}
                                  previous ={card?.previous && card?.previous}
                                />
                              ))
                            : ""}
                        </div>
                        
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Reports;
