import React, { useEffect, useRef, useState, useContext } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Select from "react-select";
import { ReactComponent as Triangle } from "../../../../public/caret-up.svg";
import { RestaurantContext } from "../../../../../../contexts/Restaurant";
//functions
import {
    _t,
    customStyle,
  } from "../../../../../../functions/Functions";
import Moment from "react-moment";


const TableList = ({data,heading,date}) => {
  let { branchForSearch } = useContext(RestaurantContext);
  const [loadAll,setLoadAll] = useState(false);
  const [dataBody,setDataBody] = useState([]);
  const [dataHead,setDataHead] = useState([]);
  const [loading,setLoading] = useState(false);
  const componentRef = useRef();

  const headingsToCheck = [
    "total stock in hand",
    "total production by item",
    "total discard"
  ];

  const getReport= (e)=>{
    componentRef.current.handleDownload();
  }

  const handleBranch = (branch) =>{
    // api call
  }

  const handleLoad = () =>{
    setDataHead(data[0] ? Object.keys(data[0]) : [])
    if(loadAll === true){
      setLoading(true);
      setDataBody(data);
      setTimeout(() => {    
        setLoading(false);
      }, 300);
    }else{
      setLoading(true);
      setDataBody(data.slice(0, 5));
      setTimeout(() => {    
        setLoading(false);
      }, 300);
    }
  }
 
  useEffect(()=>{
    const validations = headingsToCheck.includes(heading?.toLowerCase());;

    if(data){
      if(validations){
        handleLoad()
      }
    }
 },[loadAll,data])

  return (
    <div className={`row dashboard__card_list mt-3 px-0 mt-1 m-0`}>
    <ReactHTMLTableToExcel
    id="table-xls-button"
    className="download-table-xls-button btn btn-success float-right mr-3 invisible"
    table={heading}
    filename={heading}
    sheet="sheet 1"
    buttonText="Sales Detail"
    ref={componentRef}
    />
    <div className="col-12">
    <div className="row mb-2">
     <div className="list-heading font-weight-bold col-5">
      <h5 className="mt-1">{heading}</h5>
     </div>
     <div className="col-7 d-flex align-items-center justify-content-end">
     {
        heading === "Branch Wise Sales" && (
            <Select
            options={branchForSearch ? branchForSearch : []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            classNamePrefix="select"
            className="xsm-text col-4 mr-3"
            onChange={handleBranch}
            styles={customStyle}
            maxMenuHeight="180px"                            
            placeholder="Select a branch.."
            isClearable
          />
        )
     }
     {
       data && data?.length > 5 &&
      <button className="btn btn-primary btn-sm table-text rounded px-2 py-1 mr-2 mr-2"
      disabled={loading}
      onClick={()=> setLoadAll(!loadAll)}
      >
      {
       loadAll ? "Load less" : "Load All"
      }
      
      </button>
     }
     {
      data && data?.length > 0 && (
         <button
         className="btn btn-primary text-uppercase btn-sm table-text px-2 py-1 rounded mr-2"
         onClick={(e) => getReport(e)}
         >
         <i class="fa fa-table mr-1" aria-hidden="true"></i>
          download 
         </button>
      )
     }
     </div>
    </div>
    <div key={dataBody?.length} className="production-table-view" data-simplebar>
     <table id={heading}  className="table mb-0 table-borderless akash-table-for-print-padding">
      {
        date.startDate && date.endDate && (
          <tr className="d-none"> 
            <th colSpan={dataHead?.length}>
               <h3 className="text-center mt-0">
                 {heading}
               </h3>
               <p className="text-center">
                 FROM&nbsp;
                 <Moment format="LL">
                   {date.startDate}
                 </Moment>
                 &nbsp;TO&nbsp;
                 <Moment format="LL">{date.endDate}</Moment>
               </p>
            </th>
          </tr>
        )
      }
      {
      data && data?.length > 0 && (
        <>
          <thead className="production_table_head">
                <tr>
                {console.log("abcd44",dataBody)}
                  {
                   dataHead?.length && dataHead.map((item)=>{
                     return(
                       <th
                       scope="col"
                       className="list-head-text text-capitalize dashboard_border border-1 p-2 table_child_token"
                       >
                          {item}
                          {
                            item === "Total" &&
                            <Triangle className = "dashboard_table_sort cursor-pointer" onClick={()=>{
                              setLoading(true)
                              const sorting = [...dataBody].sort((a,b)=>{
                                return parseFloat(a.Total) - parseFloat(b.Total);
                              });
                              setTimeout(() => {
                                setDataBody(sorting);
                                setLoading(false)
                              }, 200);
                            }}/>
                          }
                       </th>
                     )
                   })
                  }
                  
                </tr>
          </thead>
          <tbody>
             {dataBody?.length && dataBody?.map((dataChild) => {
               return(
                 <tr>
                   {dataHead.map((data1,i) => {
                     const table = parseInt(dataChild[data1]);
                     return (
                        <td
                          scope="col"
                          className={`list-text text-capitalize dashboard_border border-1 p-2 `}
                        >
                          {(isNaN(table) || i === 0) ? dataChild[data1] : table}
                        </td>
                      
                        );
                       })}
                   </tr>
                       
                     )
                 })}
               </tbody>
               </>
               )
              }
           </table>
      </div>
     </div>
    </div>
  );
};

export default TableList;
