import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { chartThemeColorsBar, chartThemeVerticalBar } from "./index";

const parseData = (data) => {
    if (data && (data.length || Object.keys(data)?.length))
      return {
        bar: data.bar ? Object.values(data.bar) : Array.isArray(data) ? data : [],
        total: data?.total ? data.total : [],
      };
    return {
      bar: [],
      total: [],
    };
  };

const VerticalBarWidget = ({ data, heading, size }) => {
  console.log("abcd44",data,heading)
    const [formatData, setFormatData] = useState({
        bar: [],
        total: [],
      });
    
      useEffect(() => {
        setFormatData(parseData(data));
      }, [data]);
    
  return (
    <div className={`col row mx-0 mt-3 ${size} px-2`}>
    <div className="row px-0 mx-0 justify-content-between col col-12 ">
    <div className="dashboard__card col col-12 w-100 flex-basis-100">
     {heading ? <h5 className="mb-1 mt-0">{heading}</h5> : ""}
    <div className="d-flex flex-col mx-3 my-2" style={{ gap: "3rem" }}>
          {formatData.total.map((total) => (
            <div>
              <p className="mb-0 font-weight-bold">
                {total.formated_value ? total.formated_value : total.value}
              </p>
              <p className="mb-0" style={{ fontSize: "0.75rem" }}>
                {total.name}
              </p>
            </div>
          ))}
        </div>
        <Chart
          width="100%"
          height= {heading === "Production" ? "350px" :"220px"}
          type="bar"
          series={[
            {
              name: heading,
              data: formatData.bar.map((data) => parseFloat(data.value)),
            },
          ]}
          options={{
            chart: {
              type: "bar",
            },
            plotOptions: {
              bar: {
                //vertical: true,
                distributed: true,
                borderRadius: 12,
              },
            },
            colors: heading === "Production" ? chartThemeVerticalBar : chartThemeColorsBar,
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              enabled: true,
              x: {
                show: true
              },
              y: {
                enabled: true,
                formatter: function(value){
                  return value.toFixed(2);
                 }
              }
            },
            xaxis: {
              categories: formatData.bar.map((data) => data.name),
              labels: {
                 show: heading === "Production",
                 rotate: -70,
                 style:{
                  fontSize:"10px"
                 }
                },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return value;
                },
                style:{
                  fontSize:"10px"
                }
              },
             
              tickAmount: 5,
            },
            
            legend: {
              show: heading !== "Production",
              fontSize:"10px",
              position: "bottom",
          
            }
          }}
        />
      </div>
    </div>
  </div>
  )
}

export default VerticalBarWidget
