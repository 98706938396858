  import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie, deleteCookie } from "../functions/Functions";

//3rd party packages

// creating context api
const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const history = useHistory();

  // State hooks here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  // auth user
  const [authUserInfo, setAuthUserInfo] = useState({
    details: null,
    permissions: null,
  });

  //supplier
  const [supplierList, setSupplierList] = useState(null);
  const [supplierForSearch, setSupplierForSearch] = useState(null);

  //stock transfer
  const [stockTransferList, setStockTransferList] = useState(null);
  const [stockTransferForSearch, setStockTransferForSearch] = useState(null);
  const [stockTransferForPrint, setStockTransferForPrint] = useState(null);

  //till user
  const [tillUserList, setTillUserList] = useState(null);
  const [tillUserForSearch, setTillUserForSearch] = useState(null);  

  //stock adjustment
  const [stockAdjustmentList, setStockAdjustmentList] = useState(null);
  const [stockAdjustmentForSearch, setStockAdjustmentForSearch] = useState(null);  

  //opening stock
  const [openingStockList, setOpeningStockList] = useState(null);
  const [openingStockForSearch, setOpeningStockForSearch] = useState(null);

  //store requisition
  const [storeRequisitionList, setStoreRequisitionList] = useState(null);
  const [storeRequisitionForSearch, setStoreRequisitionForSearch] = useState(null);

  //stock issuance
  const [stockIssuanceList, setStockIssuanceList] = useState(null);
  const [stockIssuanceForSearch, setStockIssuanceForSearch] = useState(null);

  //party
  const [partyList, setPartyList] = useState(null);
  const [partyForSearch, setPartyForSearch] = useState(null);

  //waiter
  const [waiterList, setWaiterList] = useState(null);
  const [waiterForSearch, setWaiterForSearch] = useState(null);

  // units
  const [units, setUnits] = useState([])

  //customer
  const [customerList, setCustomerList] = useState(null);
  const [customerForSearch, setCustomerForSearch] = useState(null);

  //customer online
  const [customerListOnline, setCustomerListOnline] = useState(null);
  const [customerOnlineForSearch, setCustomerOnlineForSearch] = useState(null);

  //adminStaff
  const [adminStaffList, setAdminStaffList] = useState(null);
  const [adminStaffForSearch, setAdminStaffListforSearch] = useState(null);
  //finish goods transfer
  const [finishGoodsTransferList, setFinishGoodsTransferList] = useState(null);
  const [finishGoodsTransferListForSearch, setFinishGoodsTransferListForSearch] = useState(null);
  //finish goods discard
  const [finishGoodsDiscardList, setFinishGoodsDiscardList] = useState(null);
  const [finishGoodsDiscardListForSearch, setFinishGoodsDiscardListForSearch] = useState(null);
  //finished goods
  const [finishedGoodsList, setFinishedGoodsList] = useState(null);
  const [finishedGoodsListForSearch, setFinishedGoodsListForSearch] = useState(null);
  //supplier payment
  const [supplierPaymentList, setSupplierPaymentList] = useState(null);
  const [supplierPaymentForSearch, setSupplierPaymentForSearch] = useState(null)
  
  //finished goods acceptance
  const [finishedGoodsAcceptanceList, setFinishedGoodsAcceptanceList] = useState(null);
  const [finishedGoodsAcceptanceListForSearch, setFinishedGoodsAcceptanceListForSearch] = useState(null);
  //settlement list
  const [settlementList, setSettlementList] = useState(null);
  const [settlementListForSearch, setSettlementListForSearch] = useState(null);
  //goods adjustment
  const [goodsAdjustmentList, setGoodsAdjustmentList] = useState(null);
  const [goodsAdjustmentForSearch, setGoodsAdjustmentForSearch] = useState(null);
  // FG PACKAGE
  const [fgPackingList, setFgPackingList] = useState(null);
  const [fgPackingForSearch, setFgPackingForSearch] = useState(null);

  // FG gift list
  const [giftHampersList, setGiftHampersList] = useState(null);
  const [giftHampersForSearch, setGiftHampersForSearch] = useState(null);
  //delivery
  const [deliveryList, setDeliveryList] = useState(null);
  const [deliveryForSearch, setDeliveryListforSearch] = useState(null);

  //wastage list
  const [wastageItemList, setWastageItemList] = useState(null);
  const [wastageItemListForSearch, setWastageItemListForSearch] = useState(null);
  
  const [deliveryListReq, setDeliveryListReq] = useState(null);
  const [deliveryListReqForSearch, setDeliveryListReqForSearch] =
    useState(null);
  const [managerPasscode, setManagerPasscode] = useState(null)

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  }

  // localStorage helper
  const getDataFromLocal = (url) =>{
    return JSON.parse(localStorage.getItem(url));
  }

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  }

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated
    
    //call if authenticated
    if (getCookie() !== undefined || offline(BASE_URL + "/auth/user")) {
      getAuthUser();
      getParty();
      getWaiter();
      getUnits();
      // getCustomer();
      getAdminStaff();
      getDeliveryUser();
      getTillUser();
      // getFinishGoodsList();
      // getFinishGoodsTransferList();
      // getFinishGoodsDiscardList();
      // getFinishGoodsAcceptanceList();
      // getStockIssuance();
    }
  }, []);

  const getManagerPasscode = (data) =>{
    setLoading(true);
    const langUrl = BASE_URL + "/settings/get-passcode/" + data.branch_id ;
    return axios
    .get(langUrl, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
      .then((res) => {
        setManagerPasscode(res.data);
        localStorage.setItem(langUrl, JSON.stringify(res));
        setLoading(false);
      })
      .catch((error) => {
        if(offline(langUrl)){
          let parseRes = JSON.parse(localStorage.getItem(langUrl));
          setManagerPasscode(parseRes.data);
        }
        setLoading(false);
      });
  }

  //Helper
  const setUserInfo = (res) => {
    if(res.data[0]?.user_type === "staff" || res.data[0]?.user_type === "waiter"){
      getManagerPasscode(res.data[0])
    }
    if (res.data[0]?.is_banned === 0 || res.data[0]?.is_banned === "0") {
      setAuthUserInfo({
        ...authUserInfo,
        details: res.data[0],
        permissions: res.data[1],
      });
      setLoading(false);
    } else {
      deleteCookie();
    }
  }

  //get authenticated user // Priority -> offline
  const getAuthUser = () => {
    setLoading(true);
    const langUrl = BASE_URL + "/auth/user";
    return axios
    .get(langUrl, {
      headers: { Authorization: `Bearer ${getCookie()}` },
    })
      .then((res) => {
        setUserInfo(res);
        localStorage.setItem(langUrl, JSON.stringify(res));
      })
      .catch((error) => {
        setLoading(false);
        if(offline(langUrl)){
          let parseRes = JSON.parse(localStorage.getItem(langUrl));
          setUserInfo(parseRes);
        }
      });
  };

  // Helper
  const setSuppliersList = (res) => {
    setSupplierList(res.data[0]);
    setSupplierForSearch(res.data[1]);
    setLoading(false);
  }

  //get supplier  // Priority -> 
  const getSupplier = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-supplier";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setSuppliersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setSuppliersList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedSupplierList = (res) => {
    setSupplierList(res.data[0]);
    setSupplierForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated supplier // Priority -> 
  const setPaginatedSupplier = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-supplier?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedSupplierList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        setDataPaginating(false)
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedSupplierList(parseRes);
        }
      });

  };

  // Store Requisition context
    // Helper
    const setStoreRequisitionLists = (res) => {
      setStoreRequisitionList(res.data[0]);
      setStoreRequisitionForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get op stock  // Priority -> 
    const getStoreRequisition = () => {
      setLoading(true);
      const url = BASE_URL + "/settings/storeRequisition";
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setStoreRequisitionLists(res);
          setDataToLocal(url, res);
        }).catch((error) => {
          setLoading(false)
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setStoreRequisitionLists(parseRes);
          }
        });
    };
  
    // Helper
    const setPaginatedStoreRequisitionList = (res) => {
      setStoreRequisitionList(res.data[0]);
      setStoreRequisitionForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated StoreRequisition // Priority -> 
    const setPaginatedStoreRequisition = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/storeRequisition?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setPaginatedStoreRequisitionList(res);
          setDataToLocal(url, res);
        }).catch((error) => {
          setDataPaginating(false);
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setPaginatedStoreRequisitionList(parseRes);
          }
        });
    };

    // Stock Issuance context
    // Helper
    const setStockIssuanceLists = (res) => {
      setStockIssuanceList(res.data[0]);
      setStockIssuanceForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get stock issuance  // Priority -> 
    const getStockIssuance = () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-opening-stock";
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setStockIssuanceLists(res);
          setDataToLocal(url, res);
        }).catch((error) => {
          setLoading(false)
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setStockIssuanceLists(parseRes);
          }
        });
    };
  
    // Helper
    const setPaginatedStockIssuanceList = (res) => {
      setStockIssuanceList(res.data[0]);
      setStockIssuanceForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated StoreRequisition // Priority -> 
    const setPaginatedStockIssuance = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-opening-stock?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setPaginatedStockIssuanceList(res);
          setDataToLocal(url, res);
        }).catch((error) => {
          setDataPaginating(false);
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setPaginatedStockIssuanceList(parseRes);
          }
        });
    };

  const getUnits = () => {
    const url = BASE_URL + "/settings/unit";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(res => {
        if (res.data && res.data.length)
          setUnits(res.data[1]);
      })
      .catch(error => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setUnits(parseRes);
        }
      })
  }

  // Helper
  const setStockTransfersList = (res) => {
    setStockTransferList(res.data[0]);
    setStockTransferForSearch(res.data[1]);
    setStockTransferForPrint(res.data[2]);
    setLoading(false);
  }

  //get supplier  // Priority -> 
  const getStockTransfer = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/stockTransfer";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setStockTransfersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        setLoading(false)
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setStockTransfersList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedStockTransferList = (res) => {
    setStockTransferList(res.data[0]);
    setStockTransferForSearch(res.data[1]);
    setStockTransferForPrint(res.data[2]);
    setDataPaginating(false);
  }

  // get paginated supplier // Priority -> 
  const setPaginatedStockTransfer = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/stockTransfer?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedStockTransferList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        setDataPaginating(false);
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedStockTransferList(parseRes);
        }
      });
  };

  // Opening stock context
    // Helper
    const setOpeningStockLists = (res) => {
      setOpeningStockList(res.data[0]);
      setOpeningStockForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get op stock  // Priority -> 
    const getOpeningStock = () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-opening-stock";
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setOpeningStockLists(res);
          setDataToLocal(url, res);
        }).catch((error) => {
          setLoading(false);
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setOpeningStockLists(parseRes);
          }
        });
    };
  
    // Helper
    const setPaginatedOpeningStockList = (res) => {
      setOpeningStockList(res.data[0]);
      setOpeningStockForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated opening stock // Priority -> 
    const setPaginatedOpeningStock = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-opening-stock?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setPaginatedOpeningStockList(res);
          setDataToLocal(url, res);
        }).catch((error) => {
          setDataPaginating(false);
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setPaginatedOpeningStockList(parseRes);
          }
        });
    };

  // Helper
  const setPartiesList = (res) => {
    setPartyList(res.data[0]);
    setPartyForSearch(res.data[1]);
    setLoading(false);
  }

  //get party  // Priority -> 
  const getParty = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-party";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPartiesList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        setLoading(false);
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPartiesList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedPartyList = (res) => {
    setPartyList(res.data[0]);
    setPartyForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated party // Priority -> 
  const setPaginatedParty = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-party?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedPartyList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedPartyList(parseRes);
        }
        setDataPaginating(false);
      });

  };

  // Helper
  const setWaitersList = (res) => {
    setWaiterList(res.data[0]);
    setWaiterForSearch(res.data[1]);
    setLoading(false);
  }
  //get waiter  // Priority -> 
  const getWaiter = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-waiter";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setWaitersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setWaitersList(parseRes);
        }
        setLoading(false)
      });
  };

  // Helper
  const setPaginatedWaiterList = (res) => {
    setWaiterList(res.data[0]);
    setWaiterForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated waiter  // Priority -> 
  const setPaginatedWaiter = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-waiter?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedWaiterList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedWaiterList(parseRes);
        }
        setDataPaginating(false);
      });
  };

  // Helper
  const setCustomersList = (res, dontLoad=false) => {
    setCustomerList(res.data[0]);
    setCustomerForSearch(res.data[1]);
    if (!dontLoad) setLoading(false);
    else dontLoad(res.data[1])
  }

  //get customer  // Priority
  const getCustomer = (dontLoad=false) => {
    if (!dontLoad) setLoading(true);
    const url = BASE_URL + "/settings/get-customer";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // console.log("Resss: ", res);
        setCustomersList(res, dontLoad);
        setDataToLocal(url, res);
        return res;
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setCustomersList(parseRes);
          return parseRes;
        }
        setLoading(false);
      });
  };

  // Helper
  const setPaginatedCustomersList = (res) => {
    setCustomerList(res.data[0]);
    setCustomerForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated customer // Priority -> 
  const setPaginatedCustomer = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-customer?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedCustomersList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedCustomersList(parseRes);
        }
        setDataPaginating(false);
      });
  };

  // Helper
  const setCustomersListOnline = (res) => {
    setCustomerListOnline(res.data[0]);
    setCustomerOnlineForSearch(res.data[1]);
    setLoading(false);
  }

  //get customer online  // Priority ->
  const getCustomerOnline = () => {
    setLoading(true);
    const customerUrl = BASE_URL + "/settings/get-website-customer";
    return axios
      .get(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setCustomersListOnline(res);
        setDataToLocal(customerUrl, res);
      }).catch((error) => {
        if(offline(customerUrl)){
          let parseRes = getDataFromLocal(customerUrl);
          setCustomersListOnline(parseRes);
        }
        setLoading(false);
      });
  };
  
  // Helper
  const setPaginatedCustomerList = (res) => {
    setCustomerListOnline(res.data[0]);
    setCustomerOnlineForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated customer online  // Priority
  const setPaginatedCustomerOnline = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-website-customer?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedCustomerList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedCustomerList(parseRes);
        }
      });
  };

  // Helper
  const setAdminStaffsList = (res) => {
    setAdminStaffList(res.data[0]);
    setAdminStaffListforSearch(res.data[1]);
    setLoading(false);
  }

  //get adminStaff   // Priority -> 
  const getAdminStaff = () => {
    setLoading(true);
    const adminStaffUrl = BASE_URL + "/settings/get-admin-staff";
    return axios
      .get(adminStaffUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setAdminStaffsList(res);
        setDataToLocal(adminStaffUrl, res);
      }).catch((error) => {
        if(offline(adminStaffUrl)){
          let parseRes = getDataFromLocal(adminStaffUrl);
          setAdminStaffsList(parseRes);
        }
        setLoading(false);
      });
  };

  // Helper
  const setPaginatedAdminStaffList = (res) => {
    setAdminStaffList(res.data[0]);
    setAdminStaffListforSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated adminStaff  // Priority -> 
  const setPaginatedAdminStaff = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-admin-staff?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDataToLocal(url, res);
        setPaginatedAdminStaffList(res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedAdminStaffList(parseRes);
        }
        setDataPaginating(false);
      });
  };

  //Helper
  const setDeliveriesList = (res) => {
    setDeliveryList(res.data[0]);
    setDeliveryListforSearch(res.data[1]);
    setDeliveryListReq(res.data[2]);
    setDeliveryListReqForSearch(res.data[3]);
    setLoading(false);
  }

  //get delivery user   // Priority -> 
  const getDeliveryUser = () => {
    setLoading(true);
    const adminStaffUrl = BASE_URL + "/settings/get-delivery-man";
    return axios
      .get(adminStaffUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDeliveriesList(res);
        setDataToLocal(adminStaffUrl, res);
      }).catch((error) => {
        if(offline(adminStaffUrl)){
          let parseRes = getDataFromLocal(adminStaffUrl);
          setDeliveriesList(parseRes);
        }
        setLoading(false);
      });
  };

  //Helper
  const setPaginatedDeliveriesList = (res) => {
    setDeliveryList(res.data[0]);
    setDeliveryListforSearch(res.data[1]);
    setDeliveryListReq(res.data[2]);
    setDeliveryListReqForSearch(res.data[3]);
    setDataPaginating(false);
  }

  // get paginated delivery user // Priority -> 
  const setPaginatedDeliveryUser = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-delivery-man?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedDeliveriesList(res);
        setDataToLocal(url, res);
      }).catch((error) => {
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedDeliveriesList(parseRes);
        }
        setDataPaginating(false);
      });
  };

    // Helper
    const setPaginatedTillUserList = (res) => {
      setTillUserList(res.data[0]);
      setTillUserForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated supplier // Priority -> 
    const setPaginatedTillUser = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/till-user-list?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setDataToLocal(url, res);
          setPaginatedTillUserList(res);
        }).catch((error) => {
          setDataPaginating(false);
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setPaginatedTillUserList(parseRes);
          }
        });
    };
  
    // Helper
    const setTillUsers = (res) => {
      setTillUserList(res.data[0]);
      setTillUserForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get supplier  // Priority -> 
    const getTillUser = () => {
      setLoading(true);
      const url = BASE_URL + "/settings/till-user-list";
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setDataToLocal(url, res);
          setTillUsers(res);
        }).catch((error) => {
          setLoading(false);
          if(offline(url)){
            let parseRes = getDataFromLocal(url);
            setTillUsers(parseRes);
          }
        });
    };

  // Helper
  const setPaginatedStockAdjustmentList = (res) => {
    setStockAdjustmentList(res.data[0]);
    setStockAdjustmentForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated supplier // Priority -> 
  const setPaginatedStockAdjustment = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/stockAdjustment?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDataToLocal(url, res);
        setPaginatedStockAdjustmentList(res);
      }).catch((error) => {
        setDataPaginating(false);
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedStockAdjustmentList(parseRes);
        }
      });
  };

  // Helper
  const setStockAdjustmentsList = (res) => {
    setStockAdjustmentList(res.data[0]);
    setStockAdjustmentForSearch(res.data[1]);
    setLoading(false);
  }

  //get supplier  // Priority -> 
  const getStockAdjustment = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/stockAdjustment";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDataToLocal(url, res);
        setStockAdjustmentsList(res);
      }).catch((error) => {
        setLoading(false);
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setStockAdjustmentsList(parseRes);
        }
      });
  };

  // Helper
  const setPaginatedGoodsAdjustmentList = (res) => {
    setGoodsAdjustmentList(res.data[0]);
    setGoodsAdjustmentForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated goods adjustment // Priority -> 
  const setPaginatedGoodsAdjustment = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/finished-goods-adjustment?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDataToLocal(url, res);
        setPaginatedGoodsAdjustmentList(res);
      }).catch((error) => {
        setDataPaginating(false);
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setPaginatedGoodsAdjustmentList(parseRes);
        }
      });
  };

  // Helper
  const setGoodsAdjustmentsList = (res) => {
    setGoodsAdjustmentList(res.data[0]);
    setGoodsAdjustmentForSearch(res.data[1]);
    setLoading(false);
  }

  //get goods adjustment  // Priority -> 
  const getGoodsAdjustment = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/finished-goods-adjustment";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setDataToLocal(url, res);
        setGoodsAdjustmentsList(res);
      }).catch((error) => {
        setLoading(false);
        if(offline(url)){
          let parseRes = getDataFromLocal(url);
          setGoodsAdjustmentsList(parseRes);
        }
      });
  };

    // Helper
    const setPaginatedFgPackingList = (res) => {
      setFgPackingList(res.data[0]);
      setFgPackingForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated fg packaging // Priority -> 
    const setPaginatedFgPacking = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-packing?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setPaginatedFgPackingList(res);
        }).catch((error) => {
          setDataPaginating(false);
        });
    };
  
    // Helper
    const setFgPackageList = (res) => {
      setFgPackingList(res.data[0]);
      setFgPackingForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get fg packaging   // Priority -> 
    const getFgPacking = () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-packing";
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setFgPackageList(res);
        }).catch((error) => {
          setLoading(false);
        });
    };

     // Helper
     const setPaginatedGiftHampersList = (res) => {
      setGiftHampersList(res.data[0]);
      setGiftHampersForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated fg packaging // Priority -> 
    const setPaginatedGiftHampers = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-gift_hamper?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setPaginatedGiftHampersList(res);
        }).catch((error) => {
          setDataPaginating(false);
        });
    };
  
    // Helper
    const setGiftHampersLists = (res) => {
      setGiftHampersList(res.data[0]);
      setGiftHampersForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get fg packaging   // Priority -> 
    const getGiftHampers = () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-gift_hamper";
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setGiftHampersLists(res);
        }).catch((error) => {
          setLoading(false);
        });
    };

  // Helper
  const setFinishGoodTransfersList = (res) => {
    setFinishGoodsTransferList(res.data[0]);
    setFinishGoodsTransferListForSearch(res.data[1]);
    setLoading(false);
  }

  //get fgtransfer   // Priority -> 
  const getFinishGoodsTransferList = () => {
    setLoading(true);
    const fgtUrl = BASE_URL + "/settings/get-finished-good-transfer";
    return axios
      .get(fgtUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setFinishGoodTransfersList(res);
        // setDataToLocal(adminStaffUrl, res);
      }).catch((error) => {
        setLoading(false)
        // if(offline(adminStaffUrl)){
        //   let parseRes = getDataFromLocal(adminStaffUrl);
        //   setAdminStaffsList(parseRes);
        // }
      });
  };

  // Helper
  const setPaginatedFinishGoodsTransferList = (res) => {
    setFinishGoodsTransferList(res.data[0]);
    setFinishGoodsTransferListForSearch(res.data[1]);
    setDataPaginating(false);
  }

  // get paginated fgtransfer  // Priority -> 
  const setPaginatedFinishGoodTransfersList = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-finished-good-transfer?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // setDataToLocal(url, res);
        setPaginatedFinishGoodsTransferList(res);
      }).catch((error) => {
        setDataPaginating(false);
        // if(offline(url)){
        //   let parseRes = getDataFromLocal(url);
        //   setPaginatedAdminStaffList(parseRes);
        // }
      });
  };

    // Helper
    const setFinishGoodDiscardsList = (res) => {
      setFinishGoodsDiscardList(res.data[0]);
      setFinishGoodsDiscardListForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get fgDiscard   // Priority -> 
    const getFinishGoodsDiscardList = () => {
      setLoading(true);
      const fgtUrl = BASE_URL + "/settings/get-finished-good-discard";
      return axios
        .get(fgtUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setFinishGoodDiscardsList(res);
          // setDataToLocal(adminStaffUrl, res);
        }).catch((error) => {
          setLoading(false)
          // if(offline(adminStaffUrl)){
          //   let parseRes = getDataFromLocal(adminStaffUrl);
          //   setAdminStaffsList(parseRes);
          // }
        });
    };
  
    // Helper
    const setPaginatedFinishGoodsDiscardList = (res) => {
      setFinishGoodsDiscardList(res.data[0]);
      setFinishGoodsDiscardListForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated fgDiscard  // Priority -> 
    const setPaginatedFinishGoodDiscardsList = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-finished-good-discard?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          // setDataToLocal(url, res);
          setPaginatedFinishGoodsDiscardList(res);
        }).catch((error) => {
          setDataPaginating(false);
          // if(offline(url)){
          //   let parseRes = getDataFromLocal(url);
          //   setPaginatedAdminStaffList(parseRes);
          // }
        });
    };

    // Helper
    const setFinishGoodList = (res) => {
      setFinishedGoodsList(res.data[0]);
      setFinishedGoodsListForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get fg   // Priority -> 
    const getFinishGoodsList = () => {
      setLoading(true);
      const fgUrl = BASE_URL + "/settings/get-finished-good";
      return axios
        .get(fgUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setFinishGoodList(res);
          // setDataToLocal(adminStaffUrl, res);
        }).catch((error) => {
          setLoading(false)
          // if(offline(adminStaffUrl)){
          //   let parseRes = getDataFromLocal(adminStaffUrl);
          //   setAdminStaffsList(parseRes);
          // }
        });
    }
  
    // Helper
    const setPaginatedFinishGoodsList = (res) => {
      setFinishedGoodsList(res.data[0]);
      setFinishedGoodsListForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated fgDiscard  // Priority -> 
    const setPaginatedFinishGoodList = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-finished-good?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          // setDataToLocal(url, res);
          setPaginatedFinishGoodsList(res);
        }).catch((error) => {
          setDataPaginating(false);
          // if(offline(url)){
          //   let parseRes = getDataFromLocal(url);
          //   setPaginatedAdminStaffList(parseRes);
          // }
        });
    };

        // Helper
        const setFinishGoodAcceptanceList = (res) => {
          setFinishedGoodsAcceptanceList(res.data[0]);
          setFinishedGoodsAcceptanceListForSearch(res.data[1]);
          setLoading(false);
        }
      
        //get fgAcceptance   // Priority -> 
        const getFinishGoodsAcceptanceList = () => {
          setLoading(true);
          const fgtUrl = BASE_URL + "/settings/get-finished-goods-acceptance-list";
          return axios
            .get(fgtUrl, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setFinishGoodAcceptanceList(res);
              // setDataToLocal(adminStaffUrl, res);
            }).catch((error) => {
              setLoading(false)
              // if(offline(adminStaffUrl)){
              //   let parseRes = getDataFromLocal(adminStaffUrl);
              //   setAdminStaffsList(parseRes);
              // }
            });
        };
      
        // Helper
        const setPaginatedFinishGoodsAcceptanceList = (res) => {
          setFinishedGoodsAcceptanceList(res.data[0]);
          setFinishedGoodsAcceptanceListForSearch(res.data[1]);
          setDataPaginating(false);
        }
      
        // get paginated fgAcceptance  // Priority -> 
        const setPaginatedFinishGoodAcceptanceList = (pageNo) => {
          setDataPaginating(true);
          const url = BASE_URL + "/settings/get-finished-goods-acceptance-list?page=" + pageNo;
          return axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              // setDataToLocal(url, res);
              setPaginatedFinishGoodsAcceptanceList(res);
            }).catch((error) => {
              setDataPaginating(false);
              // if(offline(url)){
              //   let parseRes = getDataFromLocal(url);
              //   setPaginatedAdminStaffList(parseRes);
              // }
            });
        };

        // Helper
        const setSettlementLists = (res) => {
          setSettlementList(res.data[0]);
          setSettlementListForSearch(res.data[1]);
          setLoading(false);
        }
      
        //get fgAcceptance   // Priority -> 
        const getSettlementList = () => {
          setLoading(true);
          const fgtUrl = BASE_URL + "/settings/get-settlements";
          return axios
            .get(fgtUrl, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setSettlementLists(res);
              // setDataToLocal(adminStaffUrl, res);
            }).catch((error) => {
              setLoading(false)
              // if(offline(adminStaffUrl)){
              //   let parseRes = getDataFromLocal(adminStaffUrl);
              //   setAdminStaffsList(parseRes);
              // }
            });
        };
      
        // Helper
        const setPaginatedSettlementLists = (res) => {
          setSettlementList(res.data[0]);
          setSettlementListForSearch(res.data[1]);
          setDataPaginating(false);
        }
      
        // get paginated fgAcceptance  // Priority -> 
        const setPaginatedSettlementList = (pageNo) => {
          setDataPaginating(true);
          const url = BASE_URL + "/settings/get-settlements?page=" + pageNo;
          return axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              // setDataToLocal(url, res);
              setPaginatedSettlementLists(res);
            }).catch((error) => {
              setDataPaginating(false);
              // if(offline(url)){
              //   let parseRes = getDataFromLocal(url);
              //   setPaginatedAdminStaffList(parseRes);
              // }
            });
        };

        // Helper
    const setWastageList = (res) => {
      setWastageItemList(res.data[0]);
      setWastageItemListForSearch(res.data[1]);
      setLoading(false);
    }
  
    //get fg   // Priority -> 
    const getWastageList = () => {
      setLoading(true);
      const fgUrl = BASE_URL + "/settings/get-stock-wastage";
      return axios
        .get(fgUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setWastageList(res);
          // setDataToLocal(adminStaffUrl, res);
        }).catch((error) => {
          setLoading(false)
          // if(offline(adminStaffUrl)){
          //   let parseRes = getDataFromLocal(adminStaffUrl);
          //   setAdminStaffsList(parseRes);
          // }
        });
    }
  
    // Helper
    const setPaginatedWastageList = (res) => {
      setWastageItemList(res.data[0]);
      setWastageItemListForSearch(res.data[1]);
      setDataPaginating(false);
    }
  
    // get paginated fgDiscard  // Priority -> 
    const setPaginatedWastageLists = (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-stock-wastage?page=" + pageNo;
      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          // setDataToLocal(url, res);
          setPaginatedWastageList(res);
        }).catch((error) => {
          setDataPaginating(false);
          // if(offline(url)){
          //   let parseRes = getDataFromLocal(url);
          //   setPaginatedAdminStaffList(parseRes);
          // }
        });
    };

          // Helper
          const setSupplierPaymentsList = (res) => {
            setSupplierPaymentList(res.data[0]);
            setSupplierPaymentForSearch(res.data[1]);
            setLoading(false);
          }
        
          //get supp payment   // Priority -> 
          const getSupplierPaymentList = () => {
            setLoading(true);
            const url = BASE_URL + "/settings/get-supplier-payments";
            return axios
              .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
              })
              .then((res) => {
                setSupplierPaymentsList(res);          
              }).catch((error) => {
                setLoading(false)          
              });
          };
        
          // Helper
          const setPaginatedSupplierPaymentList = (res) => {
            setSupplierPaymentList(res.data[0]);
            setSupplierPaymentForSearch(res.data[1]);
            setDataPaginating(false);
          }
        
          // get paginated fgDiscard  // Priority -> 
          const getPaginatedSupplierPaymentList = (pageNo) => {
            setDataPaginating(true);
            const url = BASE_URL + "/settings/get-supplier-payments?page=" + pageNo;
            return axios
              .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
              })
              .then((res) => {          
                setPaginatedSupplierPaymentList(res);
              }).catch((error) => {
                setDataPaginating(false);          
              });
          };      

  return (
    <UserContext.Provider
      value={{
        //passcode
        managerPasscode,
        //auth user
        getAuthUser,
        setAuthUserInfo,
        authUserInfo,

        //supplier
        getSupplier,
        supplierList,
        setSupplierList,
        setPaginatedSupplier,
        supplierForSearch,
        setSupplierForSearch,

        //wastage
        getWastageList,
        wastageItemList,
        setWastageItemList,
        setPaginatedWastageLists,
        wastageItemListForSearch,
        setWastageItemListForSearch,

        //stock transger
        getStockTransfer,
        stockTransferList,
        setStockTransferList,
        setPaginatedStockTransfer,
        stockTransferForSearch,
        setStockTransferForSearch,
        stockTransferForPrint,
        setStockTransferForPrint,

        units,

        //opening stock
        getOpeningStock,
        openingStockList,
        setOpeningStockList,
        setPaginatedOpeningStock,
        openingStockForSearch,
        setOpeningStockForSearch,

        //store requisition
        getStoreRequisition,
        storeRequisitionList,
        setStoreRequisitionList,
        setPaginatedStoreRequisition,
        storeRequisitionForSearch,
        setStoreRequisitionForSearch,

        //stock issuance
        getStockIssuance,
        stockIssuanceList,
        setStockIssuanceList,
        setPaginatedStockIssuance,
        stockIssuanceForSearch,
        setStockIssuanceForSearch,

        //party
        getParty,
        partyList,
        setPartyList,
        setPaginatedParty,
        partyForSearch,
        setPartyForSearch,

        //waiter
        getWaiter,
        waiterList,
        setWaiterList,
        setPaginatedWaiter,
        waiterForSearch,
        setWaiterForSearch,

        //customer
        getCustomer,
        customerList,
        setCustomerList,
        setPaginatedCustomer,
        customerForSearch,
        setCustomerForSearch,

        //customer Online
        getCustomerOnline,
        customerListOnline,
        setCustomerListOnline,
        setPaginatedCustomerOnline,
        customerOnlineForSearch,
        setCustomerOnlineForSearch,

        //adminStaff
        getAdminStaff,
        adminStaffList,
        setAdminStaffList,
        setPaginatedAdminStaff,
        adminStaffForSearch,
        setAdminStaffListforSearch,

        //finishGoodsTransfer
        getFinishGoodsTransferList,
        finishGoodsTransferList,
        setFinishGoodsTransferList,
        setPaginatedFinishGoodTransfersList,
        finishGoodsTransferListForSearch,
        setFinishGoodsTransferListForSearch,

        //finishGoodsDiscard
        getFinishGoodsDiscardList,
        finishGoodsDiscardList,
        setFinishGoodsDiscardList,
        setPaginatedFinishGoodDiscardsList,
        finishGoodsDiscardListForSearch,
        setFinishGoodsDiscardListForSearch,

        //finishedGoods
        getFinishGoodsList,
        finishedGoodsList,
        setFinishedGoodsList,
        setPaginatedFinishGoodList,
        finishedGoodsListForSearch,
        setFinishedGoodsListForSearch,

        //finishedGoodsAcceptance
        getFinishGoodsAcceptanceList,
        finishedGoodsAcceptanceList,
        setFinishedGoodsAcceptanceList,
        setPaginatedFinishGoodAcceptanceList,
        finishedGoodsAcceptanceListForSearch,
        setFinishedGoodsAcceptanceListForSearch,

        //supplier payments
        getSupplierPaymentList,
        supplierPaymentList,
        setSupplierPaymentList,
        getPaginatedSupplierPaymentList,
        supplierPaymentForSearch,
        setSupplierPaymentForSearch,

        //goods adjustment
        getGoodsAdjustment,
        goodsAdjustmentList,
        setGoodsAdjustmentList,
        setPaginatedGoodsAdjustment,
        goodsAdjustmentForSearch,
        setGoodsAdjustmentForSearch,

        //fg package
        fgPackingList,
        setFgPackingList,
        fgPackingForSearch,
        setFgPackingForSearch,
        getFgPacking,
        setPaginatedFgPacking,

        //fg gift 
        setGiftHampersList,
        setGiftHampersForSearch,
        giftHampersList,
        giftHampersForSearch,
        setPaginatedGiftHampers,
        getGiftHampers,

        //delivery user
        getDeliveryUser,
        deliveryList,
        setDeliveryList,
        setPaginatedDeliveryUser,
        deliveryForSearch,
        setDeliveryListforSearch,
        deliveryListReq,
        setDeliveryListReq,
        deliveryListReqForSearch,
        setDeliveryListReqForSearch,

        //till user
        setPaginatedTillUser,
        getTillUser,
        setTillUserList,
        setTillUserForSearch,
        tillUserForSearch,
        tillUserList,

        //stock adjustment
        getStockAdjustment,
        stockAdjustmentList,
        setStockAdjustmentList,
        setPaginatedStockAdjustment,
        stockAdjustmentForSearch,
        setStockAdjustmentForSearch,

        //settlement list
        getSettlementList,
        settlementList, 
        setSettlementList,
        setPaginatedSettlementList,
        settlementListForSearch, 
        setSettlementListForSearch,

        //pagination
        dataPaginating,
        setDataPaginating,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
