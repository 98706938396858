import React, { useContext } from "react";
import {
  _t,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../../../contexts/Settings";
import moment from "moment";
import "./stock.css"

const ProductionPrint = ({
    name,
    print,
    production,
    newRow
}) => {
    const { t } = useTranslation();
    const {
        //common
        generalSettings,
      } = useContext(SettingsContext);

  return (
    
    <>
       {/* print */}
       <div className="d-none">
       <div ref={print}>
         {production && (
           <div className="fk-print" style={{margin:"20px 30px"}}>
             <div>
               <div className="row">
                 <div className="col-12">
                   <div className="mx-auto" style={{ width: "90px" }}>
                     <img
                       src={`${getSystemSettings(
                         generalSettings,
                         "type_logo"
                       )}`}
                       style={{ width: "100%" }}
                     />
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {production.branch?.name && production.branch.name}
                   </div>
                   <div className="my-2 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                     {name}
                   </div>

                   <div className="mt-4 my-2 print-text-stock text-capitalize row">
                     <div className="print-text-stock--bold col-4">Doc No: {production.doc_no}</div>
                     <div className="print-text-stock--bold col-4">Doc Date : {production.date && moment(production.date, "YYYY-MM-DD").format("DD-MMMM-YYYY")}</div>
                     <div className="print-text-stock--bold col-4">
                      Item :{" "}
                      {production?.item?.name && production.item.name}
                     </div>
                   </div>
                   
                   {production ? 
                   <div className="mb-2 my-2 print-text-stock text-capitalize print-text-stock--bold row">

                     <div className="print-text-stock--bold col-4">
                      Recipe Qty :{" "}
                     {production?.recipe_qty && production.recipe_qty}
                     </div>
                     <div className="print-text-stock--bold col-4">
                      Qty :{" "}
                     {production?.qty && production.qty}
                     </div>
                     <div className="print-text-stock--bold col-4">
                      Actual Qty :{" "}
                      {production?.actual_qty && production.actual_qty}
                      </div>
                   </div>:
                    null}

                    {production ? 
                      <div className="mb-2 my-2 print-text-stock text-capitalize print-text-stock--bold row">
                        <div className="print-text-stock--bold col-4">
                          Rate :{" "}
                        {production?.rate && production.rate}
                        </div>
                        <div className="print-text-stock--bold col-4">
                         Unit :{" "}
                         {production?.unit}
                         </div>
                        <div className="print-text-stock--bold col-4">
                         Amount :{" "}
                        {production?.amount && production.amount}
                        </div>
                      </div>:
                       null}

                  <div className="mb-2 my-2 print-text-stock text-capitalize print-text-stock--bold row">
                    <div className="print-text-stock--bold col-4">
                        Prd. Type :{" "}
                      {production?.type && production.type}
                    </div>

                    <div className="print-text-stock--bold col-4">
                      Add. Amt :{" "}
                      {production.additional_amount}
                    </div>

                    <div className="print-text-stock--bold col-4">
                      Remarks :{" "}
                      {production?.remarks && production.remarks}
                    </div>
                  </div>

                    {newRow !== null && [
                     newRow.length > 0 && (
                       <div className=" mt-3">
                         <table className="table table-bordered-sm table-hover ">
                           <thead className="align-middle">
                             <tr>
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border table-Child"
                                //  style={{minWidth:"180px"}}
                               >
                                 {_t(t("Name"))}
                               </th>
                               
                               <th
                                 scope="col"
                                 className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                               >
                                 {_t(t("Qty"))}
                               </th>
                               <th
                               scope="col"
                               className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                              >
                                {_t(t("Cost"))}
                              </th>  
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                             >
                               {_t(t("Cost Amount"))}
                             </th> 
                              <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                             >
                               {_t(t("Unit"))}
                             </th>                                                             
                             </tr>
                           </thead>

                           <tbody className="align-middle">
                             {newRow?.map((item, index) => {
                               return (
                                 <tr className="align-middle" key={index}>
                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {item?.ingredient_name}
                                   </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                    
                                       {item?.actual_qty}
                                   </td>
                                   
                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {item?.cost_price}
                                   </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                    {(parseFloat(item.cost_price) * parseFloat(item.actual_qty) * (production.qty ? production.qty : 1)).toFixed(3)}
                                   </td>

                                   <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                     {item?.unit_code}
                                   </td>
                                 </tr>
                               );
                             })}
                           </tbody>
                         </table>
                       </div>
                     ),
                   ]}                                      
                 </div>
               </div>
             </div>
           </div>
         )}
       </div>
     </div>
    </>
  )
}

export default ProductionPrint;
