import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import StockReport from "./StockReport";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";


const StockTransfer = () => {
  const { t } = useTranslation();
  //getting context values here
  let {
    //common
    loading,
  } = useContext(SettingsContext);

  const {
    branchForSearch,
    ingredientItemForSearch,
    getIngredientItem,
    ingredientItemStock,
  } = useContext(RestaurantContext)

  let {
    //stock transfer
    getStockTransfer,
    stockTransferList,
    setStockTransferList,
    setPaginatedStockTransfer,
    stockTransferForSearch,
    stockTransferForPrint,
    setStockTransferForSearch,
    // units
    units,
    //pagination
    dataPaginating,
  } = useContext(UserContext);

  //search result
  let [searchedStockTransfer, setSearchedStockTransfer] = useState({
    list: null,
    searched: false,
  });

  const [branchNamesObj, setBranchNamesObj] = useState({})

  //useEffect == componentDidMount
  useEffect(() => {
    getIngredientItem()
  }, []);

  useEffect(() => {
    if (branchForSearch && branchForSearch.length) {
      const obj = {}
      branchForSearch.forEach(branch => {
        obj[branch.id] = branch
      })
      setBranchNamesObj(obj)
    }
  }, [branchForSearch])

  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  }

  //search customers here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0 || stockTransferForSearch === null) {
      setSearchedStockTransfer({ ...searchedStockTransfer, searched: false });
    } else {
      let searchedList = stockTransferForSearch.filter((item) => {
        let lowerCaseTransferNo = item.transfer_no.toLowerCase();
        let lowerCaseTransferDate = item.transfer_date.toLowerCase();
        let lowerCaseBranch = (branchNamesObj[item.from_branch_id])?.name?.toLowerCase();

        return (
          lowerCaseTransferNo.includes(searchInput) ||
          lowerCaseTransferDate.includes(searchInput) ||
          lowerCaseBranch.includes(searchInput)
        );
      });
      setSearchedStockTransfer({
        ...searchedStockTransfer,
        list: searchedList,
        searched: true,
      });
    }
  };

  //print bills states and function
  const [newRow,setNewRow]=useState([]);
  const [purchaseDate,setPurchaseDate]=useState(new Date());
  let [newStockTransfer, setNewStockTransfer] = useState({
    invoice: '',
    branch: '',
    remarks: '',
  });
  const componentRef = useRef();
  
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@page { size: landscape; margin: 30px}"
  });

  const handleGetPrintData = async(id) => {
    const url = BASE_URL + "/settings/stockTransfer/" + parseInt(id);
    
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
          if(res.data && res.data.stock && res.data.stockDetails){
            let purchaseDate = new Date(res.data.stock.transfer_date);
            setPurchaseDate(purchaseDate);
            let branch=branchForSearch?.find((item)=>item.id === res.data.stock.from_branch_id)            
            setNewStockTransfer({
              ...newStockTransfer,
              invoice: res.data.stock.transfer_no,
              branch,
              remarks: res.data.stock.remarks,              
            })
            let newArr=[];
            if(res.data.stockDetails && res.data.stockDetails.length>0){
              res.data.stockDetails.map((item)=>{
                newArr.push({
                ingredient_name: item.item_name,
                qty: parseFloat(item.qty).toFixed(0),
                rate: parseFloat(item.price).toFixed(2),
                amount: parseFloat(item.amount).toFixed(2),
                unit_name: item.unit?.name,
                to_branch: item.branch?.name,
              })
              })
              setNewRow([...newArr])
            }
            handleOnlyPrint();
          }
          
        } catch(error) {
          console.log("error==>",error)
          toast.success(`${_t(t("Please try again"))}`, toastObj);
        }
  }

  const handleGetAmount = (index) => {
    let qty = 0;
    let rate = 0;
    if (newRow !== null && newRow[index].qty !== "") {
      qty = parseFloat(newRow[index].qty);
    }
    if (newRow !== null && newRow[index].rate !== "") {
      rate = parseFloat(newRow[index].rate);
    }
    return rate * qty;
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Stock Transfer"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedStockTransfer.searched
                                    ? _t(t("Stock Transfer List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <NavLink
                                  to="/dashboard/manage/stock/stock-transfer-add"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                >
                                  {_t(t("add new"))}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Transfer Number"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Transfer Date"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedStockTransfer.searched
                                ? [
                                  stockTransferList && [
                                    stockTransferList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        stockTransferList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (stockTransferList.current_page -
                                                    1) *
                                                    stockTransferList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.transfer_no}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.transfer_date}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {branchNamesObj[item.from_branch_id] && branchNamesObj[item.from_branch_id].name
                                                ? branchNamesObj[item.from_branch_id].name
                                                : ''}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">                                                
                                                <div className="d-flex justify-content-center">                                               
                                                  {/* <NavLink
                                                  className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/stock-transfer-view/${item.id}`}                                                    
                                                 >
                                                  <i className="fa fa-eye" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                 </NavLink>
                                                  <button
                                                  className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                    onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print"></i>
                                                  </button>                                                   */}
                                                  <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/stock-transfer-view/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-eye edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>                                                  
                                                 <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print edit-icon"></i>
                                                  </button>
                                                  </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedStockTransfer && [
                                      searchedStockTransfer.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedStockTransfer.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (stockTransferList.current_page -
                                                      1) *
                                                      stockTransferList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.transfer_no}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.transfer_date}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {branchNamesObj[item.from_branch_id] && branchNamesObj[item.from_branch_id].name
                                                  ? branchNamesObj[item.from_branch_id].name
                                                  : ''}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">                                                  
                                                  <div className="d-flex justify-content-center">  
                                                  {/* <NavLink
                                                  className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/stock-transfer-view/${item.id}`}                                                        
                                                 >
                                                  <i className="fa fa-eye" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                 </NavLink>                                               
                                                  <button
                                                  className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                    // onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print"></i>
                                                  </button>                                                  */}
                                                  <NavLink
                                                  className="btn btn-primary ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/stock-transfer-view/${item.id}`}
                                                  style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                 >
                                                  <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fa fa-eye edit-icon" aria-hidden="true"></i>
                                                  </div>
                                                 </NavLink>                                                  
                                                 <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print edit-icon"></i>
                                                  </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedStockTransfer.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(stockTransferList, setPaginatedStockTransfer)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(stockTransferList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedStockTransfer({
                                      ...searchedStockTransfer,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedStockTransfer,
                                    stockTransferForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
      <StockReport
      name = "Stock Transfer"
      purchaseDate={purchaseDate}
      newItem={newStockTransfer}
      newRow={newRow}
      invoiceNo = {"Transfer No"}
      inoviceDate = {"Transfer Date"}
      handleGetTotal={""}
      handleAmount={""}
      handleTaxAmount={""}
      handleDisAmount={""}
      handleGetSubTotal={""}
      handleGetAmount={""}
      stockPrint={componentRef}
      /> 
    </>
  );
};

export default StockTransfer;
